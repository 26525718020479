import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import formatPhoneNumber from '../../features/formatPhone';
import phoneIcon from '../../assets/contacts/icons8-phone.webp';
import mailIcon from '../../assets/contacts/icons8-email.webp';
import locationIcon from '../../assets/contacts/icons8-location.webp';
import instagramIcon from '../../assets/contacts/icons8-instagram.webp';
import { BubblesClockIcon } from '../../assets/contacts/bubbles_clock';

const Footer = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isAdmin } = useSelector((state) => state.auth);
  const contacts = useSelector((state) => state.contacts.data);

  const handleAdminClick = () => {
    if (isAuthenticated && isAdmin) {
      navigate('/dashboard');
    } else {
      navigate('/admin-login');
    }
  };

  const handleDoctorClick = () => {
    const doctorId = localStorage.getItem('doctorId');
    if (isAuthenticated && !isAdmin && doctorId) {
      navigate(`/doctor-dashboard/${doctorId}`);
    } else {
      navigate('/doctor-login');
    }
  };

  const workingRegime = contacts && contacts.workingRegime;
  const instagram = contacts && contacts.instagram;
  const email = contacts && contacts.email;
  const phone =
    contacts && typeof contacts.phone === 'string' ? contacts.phone : null;
  const prettyPhone = phone ? formatPhoneNumber(phone) : null;
  const address = contacts && contacts.address;
  const city = address ? address.split(',')[0] : null;
  const strLeast = address ? address.split(',').slice(1).join(',') : null;

  return (
    <>
      <footer className="w-full pt-2 px-4 tablet:px-4 tablet:pt-4 laptop:pb-2 laptop:px-8 desktop:px-16 large:px-64 gap-4 flex flex-col tablet:flex-row justify-between bg-bgdarkgray font-normal font-montserrat text-white text-sm">
        <section className="flex flex-row tablet:flex-col wrap justify-between">
          <div className="flex grow flex-col justify-start w-full text-lg gap-3">
            <h2 className="w-full tablet:w-[200px]">
              Клиника доказательной медицины
            </h2>
            {workingRegime && (
              <div className="flex flex-row gap-1 text-base items-center  align-center">
                <div className="flex w-[40px]">
                  <BubblesClockIcon />
                </div>
                <div className="flex flex-col justify-center pl-1">
                  <p className="text-start">
                    <span>Работаем с</span> {workingRegime.hours[0]} до{' '}
                    {workingRegime.hours[1]}
                  </p>
                  <p className="text-start text-break word-break word-wrap">
                    {workingRegime.days[0]} - {workingRegime.days[1]}
                  </p>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="flex flex-col shrink-0 gap-6">
          <h2 className="text-lg">О клинике</h2>
          <div className="flex flex-col grow justify-between pb-2 gap-2 text-base">
            <a
              className="h-10 flex flex-col justify-center hover:underline"
              href="/patients"
            >
              Услуги
            </a>
            <a
              className="h-10 flex flex-col justify-center hover:underline"
              href="/reviews"
            >
              Отзывы
            </a>
            <a
              className="h-10 flex flex-col justify-center hover:underline"
              href="/license"
            >
              Лицензии
            </a>
            <a
              className="h-10 flex flex-col justify-center hover:underline"
              href="/patients"
            >
              Пациентам
            </a>
          </div>
        </section>
        <section className="flex flex-col gap-6">
          <h2 className="text-lg">Контакты</h2>
          <div className="flex flex-col gap-2">
            {address && (
              <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-2">
                  <div className="flex flex-col justify-center w-[40px]">
                    <img src={locationIcon} alt="адрес клиники" />
                  </div>
                  <div className="flex flex-col">
                    <p className="">{city}</p>
                    <p className="">{strLeast}</p>
                  </div>
                </div>
              </div>
            )}
            {phone && (
              <div className="flex flex-row gap-2">
                <div className="flex flex-col justify-center w-[40px]">
                  <img src={phoneIcon} alt="номера телефона" />
                </div>
                <div className="flex flex-col grow justify-center hover:underline">
                  <a href={`tel:${phone}`}>{prettyPhone}</a>
                </div>
              </div>
            )}
            {email && (
              <div className="flex flex-row gap-2">
                <div className="flex flex-col justify-center w-[40px]">
                  <img src={mailIcon} alt="электронная почта" />
                </div>
                <div className="flex flex-col grow justify-center hover:underline">
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              </div>
            )}
            <div className="flex flex-row gap-2">
              <div className="flex flex-col justify-center w-[40px]">
                <img src={instagramIcon} alt="инстаграм" />
              </div>
              <div className="flex flex-col grow justify-center hover:underline">
                <a href={`https://www.instagram.com/${instagram}`}>
                  {instagram}
                </a>
              </div>
            </div>
          </div>
        </section>
      </footer>
      <div className="p-1 tablet:px-2 border-t-2 border-bgdarkgray flex justify-center bg-bgdarkgray text-graytext">
        <div className="flex gap-1 items-center">
          <button
            onClick={handleAdminClick}
            className="px-1 hover:underline hover:text-themeColor rounded"
          >
            админ
          </button>
          <span>|</span>
          <button
            onClick={handleDoctorClick}
            className="px-1 hover:underline hover:text-themeColor rounded"
          >
            врач
          </button>
        </div>
      </div>
    </>
  );
};

export default Footer;
